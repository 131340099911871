import amplitude from 'amplitude-js';
import { AMPLITUDE_KEY } from 'config';

class amplitudeService {
  constructor() {
    this.isInit = false;
  }

  init = () => {
    amplitude.getInstance().init(AMPLITUDE_KEY);
    this.isInit = true;
  };

  track = (name, properties = {}, callback) => {
    if (!this.isInit) this.init();
    amplitude.getInstance().logEvent(name, properties, callback);
  };

  // 充電樁資訊頁
  entryPageView = ({ serialNumber, evseId, evseName, storeName }) =>
    this.track('充電樁資訊頁_pageview', {
      充電樁代碼: serialNumber,
      充電樁ID: evseId,
      充電樁名稱: evseName,
      充電站名稱: storeName,
    });
  entryHowToUseOnClick = ({ serialNumber, evseId, evseName, storeName, state }) =>
    this.track('充電樁資訊頁-如何使用_點擊', {
      充電樁代碼: serialNumber,
      充電樁ID: evseId,
      充電樁名稱: evseName,
      充電站名稱: storeName,
      充電樁狀態: state,
    });
  entryAuthorizationOnClick = ({ serialNumber, evseId, evseName, storeName, state }) =>
    this.track('充電樁資訊頁-連鎖店授權_點擊', {
      充電樁代碼: serialNumber,
      充電樁ID: evseId,
      充電樁名稱: evseName,
      充電站名稱: storeName,
      充電樁狀態: state,
    });
  entryTermsOnClick = () => this.track('充電樁資訊頁-服務條款_點擊');
  entryPrivacyOnClick = () => this.track('充電樁資訊頁-隱私權政策_點擊');

  // 使用說明頁
  instructionsPageView = () => this.track('使用說明頁_pageview');
  instructionsOnClose = () => this.track('使用說明頁-關閉_點擊');

  // 載入前往付款頁
  loadingPaymentPageView = () => this.track('載入前往付款頁_pageview');

  // 錯誤頁
  errorPageView = ({ serialNumber, errorCode, text, error }) => {
    this.track('錯誤頁_pageview', {
      充電樁代碼: serialNumber,
      錯誤代碼: errorCode,
      錯誤標題: text,
      error: error,
    });
  };

  errorReauthorizeOnClick = ({ serialNumber, errorCode, text }) =>
    this.track('錯誤頁-返回重新授權_點擊', {
      充電樁代碼: serialNumber,
      錯誤代碼: errorCode,
      錯誤標題: text,
    });

  // 充電啟動載入頁
  chargingLoadingPageView = () => this.track('充電啟動載入頁_pageview');

  // 提示充電槍連結頁
  chargingPlugHintPageView = () => this.track('提示充電槍連結頁_pageview');
  chargingPlugHintCheckOnClick = ({ serialNumber, evseId, evseName, email }) =>
    this.track('提示充電槍連結頁-重新檢查_點擊', {
      充電樁代碼: serialNumber,
      充電樁ID: evseId,
      充電樁名稱: evseName,
      車主信箱: email,
    });

  // 查看充電中頁_pageview
  chargingPageView = ({ serialNumber, evseId, evseName, accTime, startTime, currentTime, watt, amount }) =>
    this.track('查看充電中頁_pageview', {
      充電樁代碼: serialNumber,
      充電樁ID: evseId,
      充電樁名稱: evseName,
      累計充電時間: accTime,
      開始時間: startTime,
      目前時間: currentTime,
      充電瓦數: watt,
      預計費用: amount,
    });
  chargingModifyEmailOnClick = ({ serialNumber, evseId, evseName, email }) =>
    this.track('查看充電中頁-開啟修改信箱_點擊', {
      充電樁代碼: serialNumber,
      充電樁ID: evseId,
      充電樁名稱: evseName,
      車主信箱: email,
    });
  chargingConfirmEmailOnClick = ({ serialNumber, evseId, evseName, email }) =>
    this.track('查看充電中頁-確認修改信箱_點擊', {
      充電樁代碼: serialNumber,
      充電樁ID: evseId,
      充電樁名稱: evseName,
      車主信箱: email,
    });
  chargingNavigationOnClick = ({ serialNumber, evseId, evseName, email }) =>
    this.track('查看充電中頁-導航前往取車_點擊', {
      充電樁代碼: serialNumber,
      充電樁ID: evseId,
      充電樁名稱: evseName,
      車主信箱: email,
    });

  // 提示充電完成頁
  chargingCompletePageView = ({ serialNumber, evseId, evseName, accTime, startTime, currentTime, watt, amount }) =>
    this.track('提示充電完成頁_pageview', {
      充電樁代碼: serialNumber,
      充電樁ID: evseId,
      充電樁名稱: evseName,
      累計充電時間: accTime,
      開始時間: startTime,
      目前時間: currentTime,
      充電瓦數: watt,
      預計費用: amount,
    });
  chargingCompleteModifyEmailOnClick = ({ serialNumber, evseId, evseName, email }) =>
    this.track('提示充電完成頁-開啟修改信箱_點擊', {
      充電樁代碼: serialNumber,
      充電樁ID: evseId,
      充電樁名稱: evseName,
      車主信箱: email,
    });
  chargingCompleteConfirmEmailOnClick = ({ serialNumber, evseId, evseName, email }) =>
    this.track('提示充電完成頁-導航前往取車_點擊', {
      充電樁代碼: serialNumber,
      充電樁ID: evseId,
      充電樁名稱: evseName,
      車主信箱: email,
    });
  chargingCompleteNavigationOnClick = ({ serialNumber, evseId, evseName, email }) =>
    this.track('提示充電完成頁-導航前往取車_點擊', {
      充電樁代碼: serialNumber,
      充電樁ID: evseId,
      充電樁名稱: evseName,
      車主信箱: email,
    });

  // 充電完成頁
  chargingFinishPageView = ({ serialNumber, evseId, evseName, accTime, startTime, currentTime, watt, amount }) =>
    this.track('充電完成頁_pageview', {
      充電樁代碼: serialNumber,
      充電樁ID: evseId,
      充電樁名稱: evseName,
      累計充電時間: accTime,
      開始時間: startTime,
      目前時間: currentTime,
      充電瓦數: watt,
      付款金額: amount,
    });

  // 充電強制結束頁
  chargingForceStopPageView = ({ serialNumber, evseId, evseName, accTime, startTime, currentTime, watt, amount }) =>
    this.track('充電強制結束頁_pageview', {
      充電樁代碼: serialNumber,
      充電樁ID: evseId,
      充電樁名稱: evseName,
      累計充電時間: accTime,
      開始時間: startTime,
      目前時間: currentTime,
      充電瓦數: watt,
      付款金額: amount,
    });

  chargingForceStopNavigationOnClick = ({ serialNumber, evseId, evseName, email }) =>
    this.track('充電強制結束頁-導航前往取車_點擊', {
      充電樁代碼: serialNumber,
      充電樁ID: evseId,
      充電樁名稱: evseName,
      車主信箱: email,
    });
}

export default new amplitudeService();
