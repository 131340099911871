import constate from 'constate';
import { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import repo from 'service/repository';
import validator from 'validator';
import { INTERRUPT } from 'config';
import { PATH } from 'config';
import { TIMEOUT, UNKNOWN } from 'service/error-handler';
import Navigation from 'components/navigation';

const UsChargingRecords = () => {
  const { serialNumber, id } = useParams();
  const [loaded, setLoaded] = useState(false);
  const [chargingData, setChargingData] = useState();
  const [email, setEmail] = useState('');
  const [modifyEmailDialogShow, setModifyEmailDialogShow] = useState(false);
  const [geolocation, setGeolocation] = useState({});
  const [geolocationFetchFail, setGeolocationFetchFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [refreshing, setRefreshing] = useState(false);
  const [mailModifying, setMailModifying] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const { navigateToLocation, NavigationMapDialog } = Navigation(geolocation);

  const fetchGeoLocation = async () => {
    if (Object.keys(geolocation).length === 0) {
      const [error, data] = await repo.getEvses(serialNumber);
      if (error) {
        return setGeolocationFetchFail(true);
      }

      setGeolocationFetchFail(false);
      setGeolocation(() => ({ lat: data.store.lat, lng: data.store.lng }));
    }
    navigateToLocation();
  };

  const fetchChargingRecord = useCallback(async () => {
    if (!loaded && location.state !== undefined) {
      setChargingData(location.state.data);
      history.replace(location.pathname); // 清除上一步驟帶過來的 location.state
    } else {
      const [error, data] = await repo.getChargingRecord(id);
      if (error) {
        // 如果是回傳 5xx 則讓使用者繼續等待
        if (error.toJSON().message === 'Network Error') {
          return;
        }

        return history.replace({
          pathname: `${PATH.EVSES}/${serialNumber}${PATH.ERROR}`,
          search: `error=${UNKNOWN}`,
          state: { error: JSON.stringify(error.toJSON()) },
        });
      }

      if (data.state === INTERRUPT) {
        return history.replace({ pathname: `${PATH.EVSES}/${serialNumber}${PATH.ERROR}`, search: `error=${TIMEOUT}` });
      }
      setChargingData(data);
    }

    setLoaded(true);
    setRefreshing(false);
  }, [loaded]); //eslint-disable-line

  useEffect(() => {
    fetchChargingRecord();
  }, []); //eslint-disable-line

  const modifyEmail = async () => {
    const isEmail = validator.isEmail(email);
    if (!isEmail || email === '') {
      return setErrorMessage('格式錯誤');
    }

    setMailModifying(true);
    const [error, data] = await repo.updateChargingRecord({ id, email });
    if (error) {
      return setErrorMessage('發送失敗，請重新嘗試');
    }
    setChargingData(prev => ({ ...prev, ...data }));
    setErrorMessage('');
    setEmail('');
    setMailModifying(false);
    setModifyEmailDialogShow(false);
  };

  return {
    chargingData,
    loaded,
    setLoaded,
    email,
    setEmail,
    modifyEmailDialogShow,
    setModifyEmailDialogShow,
    modifyEmail,
    errorMessage,
    setErrorMessage,
    fetchChargingRecord,
    refreshing,
    setRefreshing,
    geolocationFetchFail,
    geolocation,
    mailModifying,
    NavigationMapDialog,
    fetchGeoLocation,
  };
};

const [ChargingRecordsProvider, useChargingRecordsProvider] = constate(UsChargingRecords);

export { ChargingRecordsProvider, useChargingRecordsProvider };
