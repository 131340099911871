import { useState } from 'react';
import constate from 'constate';

const UseApp = ({ initial = {} }) => {
  const [loadingText, setLoadingText] = useState('afwefawe');

  return {
    loadingText,
    setLoadingText,
  };
};

const [AppProvider, useAppProvider] = constate(UseApp);

export { AppProvider, useAppProvider };
