import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { API_URL } from 'config';
import qs from 'qs';

const prettify = obj => {
  return camelcaseKeys(obj, { deep: true });
};
class ApiService {
  constructor() {
    this.http = this.createHttp;
    this.http.interceptors.request.use(value => this.requestInterceptor(value));
    this.http.interceptors.response.use(
      value => prettify(value),
      error => {
        console.log(error.toJSON());
        return error;
      }
    );
  }

  // config
  createHttp = axios.create({
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    baseURL: API_URL,
    timeout: 60000,
    validateStatus: status => {
      return status >= 200 && status < 300;
    },
  });

  // interceptors
  requestInterceptor = config => {
    return config;
  };

  // methods

  get = (api, params = {}) => this.http.get(`${api}`, { params });
  post = (api, ...params) => this.http.post(`${api}`, qs.stringify(...params));

  // apis

  getEvses = serialNumber => this.get(`/evses/${serialNumber}`);

  initChargingRecord = id => this.post(`charging_records/init`, { EvseID: id });

  confirmChargingRecord = id => this.post(`charging_records/${id}/confirm`);

  getChargingRecord = transactionId => this.get(`charging_records/${transactionId}`);

  updateChargingRecord = ({ id, email }) => this.post(`charging_records/${id}`, { Email: email });
}

export default new ApiService();
