import { useEffect } from 'react';
import styles from './index.module.sass';

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.privacy}>
      <h1>隱私權政策</h1>
      <h5>更新時間：2021.08 Ver. 1.0</h5>
      <br />
      <p>大聲公行動股份有限公司（以下簡稱「本公司」）係依據本條款，提供「皮卡充」之服務（以下簡稱「本服務」）。</p>
      <p>為了保障您的權益，請您務必詳讀本條款，以知悉並瞭解我們蒐集、處理、利用及揭露您的個人資料之目的。</p>
      <h3>一、個人資料的蒐集目的</h3>
      <ol>
        <li>
          當您使用本服務，本公司將透過本政策，蒐集、處理並利用您的個人資料，包含姓名、電話號碼、電子郵件地址、網路服務帳號（包括但不限於線上社群服務等）、使用車輛資料（包括但不限於廠牌型號、車牌號碼、車色、外觀影像等）、車輛位置資訊、裝置型號、序號、裝置上作業系統之類型與版本，或與其使用本服務相關之交易資訊（包括但不限於信用卡資訊等），及其他與本服務相關而得直接或間接識別使用者身分之資料（以下合稱「個人資料」）。（上述特定目的，法定編號包含：〇四〇行銷、〇六三
          非公務機關依法定義務所進行個人資料之蒐集處理及利用、〇六七
          信用卡、現金卡、轉帳卡或電子票證業務、〇六九契約、類似契約或其他法律關係事務、〇八一個人資料之合法交易業務、〇九〇
          消費者、客戶管理與服務、〇九一消費者保護、〇九八
          商業與技術資訊、一三五資（通）訊服務、一三六資（通）訊與資料庫管理、一四八網路購物及其他電子商務服務、一五二
          廣告或商業行為管理、一五七調查、統計與研究分析、一八一 其他經營合於營業登記項目或組織章程所定之業務）
        </li>
        <li>
          當您選擇透過本服務以信用卡付款時，本公司將會要求提供信用卡資訊以處理該項付款，並提供信用卡資訊及其他個人資料予需處理該付款之其他公司。
        </li>
        <li>
          您得自行決定是否提供本公司所要求之任何個人資料。您應確保其提供予本公司之所有個人資料係完整、準確、真實且正確。如未依上述要求提供個人資料，可能導致您無法繼續使用本服務。
        </li>
      </ol>
      <h3>二、個人資料的取得</h3>
      <ol>
        <li>
          當您使用本服務時，會要求您依選用的支付方式，登入支付App或輸入信用卡資訊以進行付款。當您登入支付App或輸入信用卡資訊進行付款時，會要求您輸入電子信箱以寄送電子發票。
        </li>

        <li>當您使用本服務時，會要求您輸入電子信箱。</li>

        <li>
          當您在使用本服務時，本公司可能會蒐集到以下資料：
          <ol>
            <li>
              得以辨識您個人之資料，例如：姓名、個人照片、住址、電話號碼、電子郵件地址、網路服務帳號、電子簽章、憑證序號、提供網路身分認證或申辦查詢服務之記錄及其他任何可辨識資料本人者。
            </li>
            <li>
              得以辨識您財務之資料，例如：金融機構帳戶之號碼與姓名、信用卡或簽帳卡之號碼、保險單號碼、個人之其他號碼或帳戶等。
            </li>
            <li>
              政府資料得以辨識個人者，例如：身分證統一編號、統一證號、稅籍編號、保險憑證號碼、駕籍資料（如駕駛執照及其他）、車籍資料（如行車執照、廠牌型號、車牌號碼及其他）等。
            </li>
            <li>
              其他使用者因使用本服務所產生之技術數據，例如：裝置資料、IP
              地址、使用本服務之時間、位置資料、財務交易資訊以及其他得以辨識個人休閒活動及興趣、生活格調等之資料。
            </li>
          </ol>
        </li>
      </ol>
      <p>
        （上述資料之類別，法定編號包含：C〇〇一 辨識個人者、C〇〇二辨識財務者、C〇〇三
        政府資料中之辨識者、C〇一一個人描述、C〇三五休閒活動及興趣、C〇三六 生活格調、C〇三九 執照或其他許可、C〇九三
        財務交易）
      </p>
      <h3>三、個人資料利用之期間、地區、對象及方式</h3>
      <p>本公司收集到的資料，將做如下處理：</p>
      <ol>
        <li>
          您所提供的基本個人資料（如姓名），將做為身分判定的依據；您所提供的信用卡卡號，將作為提供服務優惠資訊的依據。
        </li>
        <li>您的使用資料及技術資料，將作為改進本服務內容用。</li>
        <li>
          您的個人資料將提供本公司及合作廠商進行個人化行銷服務之用。本公司將透過行動推播或寄送電子郵件之方式，分享本公司及合作廠商之優惠消息。您於接受本服務時，已表示您的同意，若您不希望收到本公司及合作廠商之優惠消息，可能導致您無法繼續使用本服務。
        </li>
        <li>
          資訊授權
          <ol>
            <li>
              本公司將在履行本服務的必要範圍（包括但不限於本服務之履行、消費爭議之處理，以及優惠資訊之提供等）內，將您部分或全部的個人資料，提供予第三方服務提供者進行處理。於此情形，本公司將採取行動以確保第三方服務提供者遵守個資法，包括適當地評估第三方服務提供者之資格與能力，於與第三方服務提供者之相關合約中訂定保密條款，並建立適當的資訊管理系統。本公司得提供第三方服務者處理之範圍，應以本服務條款中已經明示，且與本服務相關之項目為限，包含停車業者、加油站業者、行車周邊服務供應商、信用卡發行機構、銀行或與第三方支付服務業者。
            </li>
            <li>
              本公司可能與第三方共用綜合性的非個人資料。「綜合性非個人資料」是指記錄有關用戶分組收集的資料，這些資料在經過分組後不再反映或指向某個特定的可被辨識的使用者。上述資訊之處理與利用，可能由本公司之關係企業或其他合作夥伴進行，或於台灣外發生。除非有當地法律除外情況，本公司不會將這些資訊用做其他用途。本公司亦不會未經您事先同意向第三者透露其個人資料，但有法律依據或合約義務者，不在此限。
            </li>
            <li>
              前項但書之情形包括不限於：
              <ol>
                <li>經由您書面同意。</li>
                <li>法律明文規定。</li>
                <li>為免除您生命、身體、自由或財產上之危險。</li>
                <li>
                  與公務機關或學術研究機構合作，基於公共利益為統計或學術研究而有必要，且資料經過提供者處理或蒐集者依其揭露方式無從識別特定之當事人。
                </li>
                <li>防止他人權益之重大危害。</li>
                <li>有利於您的權益。</li>
                <li>但因國家重大利益或為配合執法機關調查而根據法律而需要透露者則除外。</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          您的個人資料，將於本公司實際營運本服務的地域內，進行處理與利用。前述處理與利用之方式，將以數據化、自動化的方法為之。
        </li>
        <li>
          上述之資料除綜合性非個人資料外，本公司得蒐集、處理或利用您個人資料的期間，自您開始使用本服務之時，至您終止使用本服務時，或本公司停止向您供應本服務時為止（以時間較早者為準）。
        </li>
      </ol>
      <h3>四、個人資料的自主權</h3>
      <ol>
        <li>
          您本人可查詢、複製、或閱覽屬於您的個人資料，若非本人或於本公司尚未查證您為本人前，您不得申請查詢、複製、請求閱覽不屬於本帳號之個人資料及帳號服務。如非前述情況之申請者，必要時您將提供相關個人資料及書面資訊，使本公司可協助處理作業程序。
        </li>
        <li>如您的個人資料有所遺漏或錯誤，您得隨時請求本公司進行補充或更正。</li>
        <li>
          您本人可要求停止蒐集、處理、利用或刪除屬於您的個人資料；惟您若要求本公司停止蒐集、處理、利用或刪除您的個人資料，可能造成本公司無法對您提供本服務部分或全部之內容，於此情況下，本公司得視具體情況，終止與您之間的服務條款。
        </li>
        <li>
          於妨害國家重大利益、妨害公務機關執行法定職務、妨害蒐集機關或第三人重大利益時，本公司有權拒絕您主動提出變更、刪除或異動個人資料之請求。
        </li>
      </ol>
      <h3>五、個人資料的安全</h3>
      <ol>
        <li>
          為保護您的隱私，本公司提供必要之技術及措施保護個人資料，只由經過授權的人員才能接觸您的個人資料，相關處理人員具有一定程度之教育訓練並皆簽有保密合約，如有違反保密義務者，將會受到相關的法律處分。
        </li>
        <li>
          如因業務需要有必要委託其他單位提供服務時，本公司亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。
        </li>
        <li>
          儘管本公司盡力保護您的個人資料，然仍無法保證您的個人資料百分之百不會遭受第三人之非法攔截、傳送或盜駭。前述事故發生時，本公司將及時通報您，並對此啟動相關應變措施及證據保存。
        </li>
        <li>
          您的個人資料，請您妥善保管，避免外洩。您的信用卡資料，請您妥善保管，避免為他人之不法用途。您同意在使用本服務所留存的資料與事實相符，如嗣後發現你的個人資料遭他人非法使用或有任何異常時，應即時通知本公司。您同意於使用本服務服務時，所提供及使用之資料皆為合法，並無侵害第三人權利、違反第三方協議或涉及任何違法行為。如因使用服務而致第三人損害，除本公司故意或重大過失外，本公司並不負擔相關賠償責任。
        </li>
      </ol>
      <h3>六、 隱私權及個人資料保護政策的修訂與效力</h3>
      <ol>
        <li>本公司將不定時更新本政策，並遵循中華民國隱私權與個人資料保護相關法令。</li>
        <li>本政策構成使用條款的一部分，本政策之條款如有一部無效時，不影響其他條款之效力。</li>
        <li>
          如您不同意本政策之任何條款或修改變更之內容，請您立即停止使用本服務，如繼續使用，則視為同意更新的條款內容。
        </li>
        <li>
          如果您對本公司的隱私權或個人資料保護政策有任何問題，請聯絡 {' '}
          <a href="mailto:charge@pickncharge.xyz">charge@pickncharge.xyz</a>。
        </li>
      </ol>
    </div>
  );
};

export default Privacy;
