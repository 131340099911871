import { useState } from 'react';
import className from 'classnames/bind';
import styles from './index.module.sass';
const cx = className.bind(styles);

const Navigation = (geolocation = {}) => {
  const [navigationMapDialogShow, setNavigationMapDialogShow] = useState(false);

  const googleMapLink = `https://www.google.com/maps/dir/?api=1&destination=${geolocation.lat},${geolocation.lng}`;
  const appleMapLink = `http://maps.apple.com/?daddr=${geolocation.lat},${geolocation.lng}&dirflg=d`;

  const navigateToLocation = () => {
    const isAppleDevice = () => {
      const device = ['iPhone', 'iPad', 'Mac'];
      return device.find(d => navigator.platform.indexOf(d) !== -1 || navigator.platform.includes(d));
    };

    if (isAppleDevice()) {
      setNavigationMapDialogShow(true);
    } else {
      window.location.href = googleMapLink;
    }
  };

  const NavigationMapDialog = () => {
    const dialogClass = cx({
      'navigation-map-dialog': true,
      show: navigationMapDialogShow,
    });

    const onHide = () => setNavigationMapDialogShow(false);

    return (
      <>
        <div className={dialogClass}>
          <div className={styles.mask} onClick={onHide} />
          <ul className={styles.direct}>
            <li
              onClick={() => {
                window.location.href = googleMapLink;
                onHide();
              }}
            >
              Google Maps
            </li>
            <li
              onClick={() => {
                window.location.href = appleMapLink;
                onHide();
              }}
            >
              Apple Maps
            </li>
          </ul>
          <div className={styles.cancel} onClick={onHide}>
            取消
          </div>
        </div>
      </>
    );
  };

  return {
    navigateToLocation,
    NavigationMapDialog,
  };
};

export default Navigation;
