import Loading from 'components/loading';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { AUTH_FAIL, CONFIRM_FAIL, EVSE_IN_USE } from 'service/error-handler';
import repo from 'service/repository';
import amplitude from 'service/amplitude';
import { PATH } from 'config';

const ChargingRecordsConfirm = () => {
  const { serialNumber, id } = useParams();
  const history = useHistory();

  useEffect(() => {
    amplitude.chargingLoadingPageView();

    const init = async () => {
      const [error, data] = await repo.confirmChargingRecord(id);
      if (error) {
        let { error: errorMessage } = error.response?.data;

        const errorCode = () => {
          switch (errorMessage) {
            // 授權失敗 messages
            case 'payment_state error':
              return AUTH_FAIL;
            // 充電樁使用中
            case 'Evse still in use':
              return EVSE_IN_USE;
            default:
              return CONFIRM_FAIL;
          }
        };

        return history.replace({
          pathname: `${PATH.EVSES}/${serialNumber}${PATH.ERROR}`,
          search: `error=${errorCode()}`,
          state: { error: JSON.stringify(error.toJSON()) },
        });
      }
      history.replace(`${PATH.EVSES}/${serialNumber}${PATH.CHARGING_RECORDS}/${data.id}`, { data });
    };

    init();
  }, []); // eslint-disable-line

  return <Loading loadingText="請稍候..." />;
};

export default ChargingRecordsConfirm;
