import { useEffect } from 'react';
import smoothscroll from 'smoothscroll-polyfill';

const UseInit = () => {
  useEffect(() => {
    // polyfill
    smoothscroll.polyfill();

    // 導向 https
    const { protocol, host, pathname } = window.location;
    if (protocol.indexOf('https') === -1) {
      window.location.href = `https://${host}${pathname}`;
    }

    // set css variable height
    let h = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--initVh', `${h}px`);
    document.documentElement.style.setProperty('--vh', `${h}px`);

    const onResize = () => {
      let h = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${h}px`);
    };

    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);
};

export default UseInit;
