import styles from './index.module.sass';
import Lottie from 'react-lottie-player';
import loading from 'images/pnc_loading.json';
// import JlottiePlayer from 'components/jlottie-player';

const Loading = ({ loadingText }) => {
  return (
    <div className={styles.loading}>
      <div className={styles.lottie}>
        <Lottie loop animationData={loading} play style={{ width: 70, height: 80 }} />
        {/* <JlottiePlayer animationData={loading} style={{ width: 70, height: 80 }} /> */}
      </div>
      <span>{loadingText}</span>
    </div>
  );
};

export default Loading;
