// package
import React, { useCallback, useEffect, useState, useRef, forwardRef } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import classnames from 'classnames/bind';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import Lottie from 'react-lottie-player';
import styles from './index.module.sass';
import Wrapper from 'components/wrapper';
import Button from 'components/button';
import Loading from 'components/loading';
import { chargingPowerTwoDecimal } from 'utils/helper';
import amplitude from 'service/amplitude';
import { EVSE_NOT_FOUND, INIT_FAIL } from 'service/error-handler';
import repo from 'service/repository';
import use01 from 'images/img-use-01.jpg';
import use02 from 'images/img-use-02.jpg';
import use03 from 'images/img-use-03.jpg';
import use04 from 'images/img-use-04.jpg';
import arrow from 'images/icon-arrow.svg';
import logo from 'images/icon-pnc-logo-text.svg';
import iconQuestiuon from 'images/icon-question.svg';
import loading from 'images/pnc_loading.json';
import {
  PATH,
  DISABLE,
  REMOVE,
  UNKNOWN,
  AVAILABLE,
  UNAVAILABLE,
  CHARGING,
  PREPARING,
  FINISHING,
  WAITING,
} from 'config';
import { SUSPEND_EVSE } from 'config';
import { SUSPEND_EV } from 'config';

const cx = classnames.bind(styles);

const resultByEvseState = (state, result) => {
  switch (state) {
    case AVAILABLE:
    case PREPARING:
      return result.enabled;
    case WAITING:
    case CHARGING:
    case FINISHING:
    case SUSPEND_EVSE:
    case SUSPEND_EV:
      return result.charging;
    case UNAVAILABLE:
    case DISABLE:
    case UNKNOWN:
    case REMOVE:
      return result.disabled;
    default:
      return result.default;
  }
};

const ManualDialog = forwardRef(({ show, onClose }, ref) => {
  useEffect(() => {
    if (show) amplitude.instructionsPageView();
  }, [show]);

  const manualList = [
    {
      image: use01,
      text: (
        <>
          確認充電樁編號
          <br />
          同意授權付款
        </>
      ),
    },
    {
      image: use02,
      text: (
        <>
          預授權付款
          <br />
          選擇付款方式與發票開立方式
        </>
      ),
    },
    {
      image: use03,
      text: (
        <>
          插入充電槍
          <br />
          自動開始充電計費
        </>
      ),
    },
    {
      image: use04,
      text: (
        <>
          即時追蹤充電狀態
          <br />
          欲結束充電，拔槍即可自動扣款
        </>
      ),
    },
  ];

  const [index, setIndex] = useState(0);
  const [isMoving, setIsMoving] = useState(false);
  const [pointStart, setPointStart] = useState([]);
  const [pointMove, setPointMove] = useState(0);
  const [direction, setDirection] = useState('');

  useEffect(() => {
    if (isMoving) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'inherit';
    }
  }, [isMoving]);

  const onTouchStart = e => {
    setPointStart([e.touches[0].clientX, e.touches[0].clientY]);
  };

  const onTouchMove = e => {
    // 移動距離(touch)
    let result = pointStart[0] - e.touches[0].clientX;
    // 計算移動方向，若移動方向為垂直則不執行carousel，若為水平則移動carousel
    // 並且不會隨著移動的斜率改變方向
    if (e.touches && direction === '') {
      const endY = e.touches[0].clientY,
        startY = pointStart[1],
        endX = e.touches[0].clientX,
        startX = pointStart[0];
      const m = (endY - startY) / (endX - startX);
      if (m < 1 && m > -1) {
        setIsMoving(true);
        setPointMove(result);
        setDirection('horizonal');
      } else {
        setDirection('vertical');
      }
    } else if (e.touches && direction === 'horizonal') {
      setPointMove(result);
    }
  };

  const onTouchEnd = () => {
    if (Math.abs(pointMove) > window.innerWidth / 5) {
      if (pointMove > 0) {
        nextSlide();
      } else {
        previousSlide();
      }
    } else {
      setIsMoving(false);
      setPointStart([]);
      setPointMove(0);
      setDirection('');
    }
  };

  const previousSlide = () => {
    setIndex(prev => (prev === 0 ? 0 : prev - 1));
    setIsMoving(false);
    setPointStart([]);
    setPointMove(0);
    setDirection('');
  };

  const nextSlide = () => {
    setIndex(prev => (prev === manualList.length - 1 ? prev : prev + 1));
    setIsMoving(false);
    setPointStart([]);
    setPointMove(0);
    setDirection('');
  };

  const ulOffset = useCallback(() => {
    return -(index * window.innerWidth + pointMove);
  }, [pointMove, index]);

  return (
    <div
      ref={ref}
      className={cx({ mask: true, show })}
      id="mask"
      onClick={e => {
        if (e.target.id === 'mask') onClose();
      }}
      data-testid="manual"
    >
      <div className={styles.manual}>
        <div className={styles.close} onClick={onClose} />
        <div className={styles.content}>
          <div className={styles.number}>{index + 1}</div>
          <img
            src={arrow}
            alt="arrow-left"
            className={cx({ arrow: true, left: true, show: index !== 0 })}
            onClick={previousSlide}
          />
          <img
            src={arrow}
            alt="arrow-right"
            className={cx({ arrow: true, right: true, show: index !== manualList.length - 1 })}
            onClick={nextSlide}
          />
          <ul
            className={styles.list}
            style={{
              transform: `translateX(${ulOffset()}px)`,
              transition: isMoving ? '' : 'all 0.2s ease',
            }}
          >
            {manualList.map((item, i) => {
              return (
                <li onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} key={i}>
                  <div className={styles.image}>
                    <div style={{ backgroundImage: `url(${item.image})` }} />
                  </div>
                  <div className={styles.text}>{item.text}</div>
                </li>
              );
            })}
          </ul>
          <ul className={styles.indicator}>
            {manualList.map((_, i) => {
              const liClass = cx({
                active: index === i,
              });
              return <li className={liClass} key={i} />;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
});

const Entry = () => {
  const { serialNumber } = useParams();
  const [manualDialogShow, setManualDialogShow] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const manualDialogRef = useRef();
  const [evseData, setEvseData] = useState({});
  const [authing, setAuthing] = useState(false);
  const history = useHistory();

  const evseStateText = resultByEvseState(evseData.state, {
    enabled: '可充電',
    charging: '充電中',
    disabled: '停用',
    default: '停用',
  });

  const evseState = resultByEvseState(evseData.state, {
    enabled: 'enabled',
    charging: 'charging',
    disabled: 'disabled',
    default: 'disabled',
  });

  useEffect(() => {
    const init = async () => {
      const [error, data] = await repo.getEvses(serialNumber);
      if (error) {
        return history.push({
          pathname: `${PATH.EVSES}/${serialNumber}${PATH.ERROR}`,
          search: `error=${EVSE_NOT_FOUND}`,
          state: { error: JSON.stringify(error.toJSON()) },
        });
      }
      setEvseData(data);
      setLoaded(true);
    };
    init();
  }, []); // eslint-disable-line

  // lock body when open manual dialog
  useEffect(() => {
    if (manualDialogShow) {
      disableBodyScroll(manualDialogRef.current);
    } else {
      enableBodyScroll(manualDialogRef.current);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [manualDialogShow]);

  useEffect(() => {
    if (loaded) {
      amplitude.entryPageView({
        serialNumber,
        evseId: evseData.id,
        evseName: evseData.uid,
        storeName: evseData.store.name,
      });
    }
  }, [loaded]); //eslint-disable-line

  const onStartPayment = async () => {
    setAuthing(true);
    const [error, data] = await repo.initChargingRecord(evseData.id);
    if (error) {
      setAuthing(false);
      return history.push({
        pathname: `${PATH.EVSES}/${serialNumber}${PATH.ERROR}`,
        search: `error=${INIT_FAIL}`,
        state: { error: JSON.stringify(error.toJSON()) },
      });
    }
    window.location.href = data.paymentUrl;
  };

  const ChargingInfoBlock = ({ state, chargingPeriod, uid, watt }) => {
    // 確認可顯示充電樁資訊的狀態
    const inUseState = [AVAILABLE, PREPARING, WAITING, CHARGING, FINISHING, SUSPEND_EVSE, SUSPEND_EV];
    const isAvailable = inUseState.indexOf(state) !== -1;

    const infoClass = cx({
      info: true,
      [evseState]: true,
    });

    return (
      <div className={infoClass}>
        <div className={styles.heading}>
          <span className={styles.code} data-testid="code">
            {uid}
          </span>
          <div className={cx({ 'pile-state': true, [evseState]: true })} data-testid="pile-state">
            <span>{evseStateText}</span>
          </div>
        </div>
        {isAvailable && (
          <div className={styles.list} data-testid="pile-list">
            <div className={styles.item}>
              <div className={styles.name}>一般費率</div>
              <div className={styles.value} data-testid="rate">
                ${chargingPeriod.pricePerMinute}/分鐘
              </div>
            </div>
            <div className={styles.hint}>充滿電未拔槍將持續計費</div>
            <div className={styles.item}>
              <div className={styles.name}>充電功率</div>
              <div className={styles.value} data-testid="power">
                {chargingPowerTwoDecimal(watt)} kWh
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const ActionBlock = ({ id, uid, store }) => {
    const Available = () => {
      return (
        <div data-testid="available">
          <div className={styles.button}>
            <Button
              onClick={() => {
                amplitude.entryAuthorizationOnClick({
                  serialNumber,
                  evseId: id,
                  evseName: uid,
                  storeName: store.name,
                  state: '可充電',
                });

                onStartPayment();
              }}
            >
              授權付款並啟動充電
            </Button>
          </div>
          <div className={styles.agreement}>
            開始服務即代表同意
            <Link to={PATH.TERMS} onClick={() => amplitude.entryTermsOnClick()}>
              服務條款
            </Link>
            &nbsp;/&nbsp;
            <Link to={PATH.PRIVACY} onClick={() => amplitude.entryPrivacyOnClick()}>
              隱私權政策
            </Link>
          </div>
        </div>
      );
    };

    const Disable = () => {
      return (
        <div className={styles.message} data-testid="disable">
          此充電樁已停用，請嘗試其他充電樁。
        </div>
      );
    };

    const Content = resultByEvseState(evseData.state, {
      enabled: <Available />,
      charging: <></>,
      disabled: <Disable />,
      default: <></>,
    });

    return <div data-testid="action">{Content}</div>;
  };

  if (!loaded) {
    return <Loading />;
  }

  if (authing) {
    amplitude.loadingPaymentPageView();
    return <Loading loadingText="正在前往麻吉雲付款頁面" />;
  }

  return (
    <>
      <ManualDialog
        ref={manualDialogRef}
        show={manualDialogShow}
        onClose={() => {
          amplitude.instructionsOnClose();
          setManualDialogShow(false);
        }}
      />
      <Wrapper bgTheme="green-blue">
        <div className={cx({ entry: true, loaded })}>
          <div className={styles.card}>
            <div className={styles.logo}>
              <div className={styles.lottie}>
                <Lottie loop animationData={loading} play style={{ width: 50, height: 50 }} />
              </div>
              <img src={logo} alt="pick-n-charge-logo" />
            </div>
            <ChargingInfoBlock {...evseData} />
            <ActionBlock {...evseData} />
          </div>
          <div
            className={styles['how-to-use']}
            onClick={() => {
              amplitude.entryHowToUseOnClick({
                serialNumber,
                evseId: evseData.id,
                evseName: evseData.uid,
                storeName: evseData.store.name,
                state: evseStateText,
              });
              setManualDialogShow(true);
            }}
            data-testid="how-to-use"
          >
            如何使用
            <img src={iconQuestiuon} alt="question" />
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default Entry;
