import { Link } from 'react-router-dom';
import styles from './index.module.sass';

const Button = ({ to = '', onClick, children }) => {
  if (to) {
    return (
      <Link to={to} onClick={onClick} className={styles.button}>
        {children}
      </Link>
    );
  }

  return (
    <button onClick={onClick} className={styles.button}>
      {children}
    </button>
  );
};

export default Button;
