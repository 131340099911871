import axios from 'axios';
import api from 'service/api-service';

const apiCheck = async promise => {
  try {
    const value = await promise;
    if (value instanceof Error) throw value;
    if (axios.isCancel(value)) throw value;
    return [null, value];
  } catch (error) {
    return [error];
  }
};

class Repository {
  getEvses = async serialNumber => {
    const [error, value] = await apiCheck(api.getEvses(serialNumber));
    if (error) return [error];
    return [null, value.data];
  };

  initChargingRecord = async evseId => {
    const [error, value] = await apiCheck(api.initChargingRecord(evseId));
    if (error) return [error];
    return [null, value.data];
  };

  confirmChargingRecord = async id => {
    const [error, value] = await apiCheck(api.confirmChargingRecord(id));
    if (error) return [error];
    return [null, value.data];
  };

  getChargingRecord = async id => {
    const [error, value] = await apiCheck(api.getChargingRecord(id));
    if (error) return [error];
    return [null, value.data];
  };

  updateChargingRecord = async (...params) => {
    const [error, value] = await apiCheck(api.updateChargingRecord(...params));
    if (error) return [error];
    return [null, value.data];
  };
}

export default new Repository();
