export const PATH = Object.freeze({
  EVSES: '/evses',
  CHARGING_RECORDS: '/charging_records',
  ERROR: '/error',
  TERMS: '/terms',
  PRIVACY: '/privacy',
});

const deployEnv = process.env.REACT_APP_DEPLOY_ENV === 'prod' ? 'prod' : 'alpha';
export const isAlpha = deployEnv === 'alpha';

const ALPHA_API_URL = 'https://api-alpha.pickncharge.xyz';
const PROD_API_URL = 'https://api.pickncharge.xyz';
const ALPHA_AMPLITUDE_KEY = '49ee79f9b32e96141c5e8866b31d5cc1';
const PROD_AMPLITUDE_KEY = '125bfc75448a4a41133c5c6dec3ce000';
const ALPHA_MAINTENANCE_CONFIG_URL = 'https://maintenance-alpha.pklotcorp.com/config.json';
const PROD_MAINTENANCE_CONFIG_URL = 'https://maintenance.autopass.xyz/config.json';

export const API_URL = isAlpha ? ALPHA_API_URL : PROD_API_URL;
export const AMPLITUDE_KEY = isAlpha ? ALPHA_AMPLITUDE_KEY : PROD_AMPLITUDE_KEY;
export const MAINTENANCE_CONFIG_URL = isAlpha ? ALPHA_MAINTENANCE_CONFIG_URL : PROD_MAINTENANCE_CONFIG_URL;

export const CUSTOMER_SERVICE_MAIL = 'charge@pickncharge.xyz';
export const LINE_LINK = '@189tbruf';

// 充電樁狀態
// - 可充電
export const AVAILABLE = 'Available';
export const PREPARING = 'Preparing';
// - 充電中
export const WAITING = 'Waiting';
export const CHARGING = 'Charging';
export const FINISHING = 'Finishing';
export const SUSPEND_EVSE = 'SuspendedEVSE';
export const SUSPEND_EV = 'SuspendedEV';
// - 停用
export const UNAVAILABLE = 'Unavailable';
export const DISABLE = 'Disable';
export const UNKNOWN = 'unknown';
export const REMOVE = 'Remove';

// 充電狀態
// - 等待中
export const INIT = 'init';
export const INIT_AUTH = 'init_auth';
export const START_SESSION = 'start_session';
export const WAITING_CHARGING = 'waiting_charging';
// - 授權失敗
export const INTERRUPT = 'interrupt';
// - 充電中
export const IN_CHARGING = 'charging';
// - 充電中 & 低電量
export const COMPLETE_CHARGING = 'complete_charging';
// - 完成充電
export const FINISH_CHARGING = 'finish_charging';
// - 強制結束充電
export const COMPLETE_SESSION = 'complete_session';
export const FORCE_STOP_CHARGING = 'force_stop_charging';

// 充電紀錄圖示
export const MENTOS_INIT = 'init';
export const MENTOS_LOADED = 'loaded';
export const MENTOS_READY = 'ready';
export const MENTOS_ANIMATE = 'animate';
