import React from 'react';
import iconEmerg from 'images/img_emerg.svg';
import styles from './index.module.sass';
import iconPicknCharge from 'images/logo_pickncharge.svg';

const Maintenance = ({ title, content }) => {
  const contentText = content.split('\n');
  return (
    <div className={styles.maintenance}>
      <div className={styles.main}>
        <img className={styles.emerg} src={iconEmerg} alt="emerg" />
        <h1>{title}</h1>
        <p>
          {contentText.map(t => (
            <>
              {t}
              <br />
            </>
          ))}
        </p>
      </div>
      <img className={styles.footer} src={iconPicknCharge} alt="power" />
    </div>
  );
};

export default Maintenance;
