import { Link } from 'react-router-dom';
import styles from './index.module.sass';
import iconTip from 'images/icon-tip.svg';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import qs from 'query-string';
import { errorHandler } from 'service/error-handler';
import { useEffect } from 'react';
import { PATH } from 'config';
import amplitude from 'service/amplitude';

const Error = () => {
  const history = useHistory();
  const location = useLocation();
  const { serialNumber } = useParams();
  const { error } = qs.parse(location.search);
  const { title, desc, button } = errorHandler(error);

  useEffect(() => {
    amplitude.errorPageView({
      serialNumber,
      errorCode: error,
      text: title,
      error: location.state?.error,
    });
  }, []); //eslint-disable-line

  useEffect(() => {
    const handleHistory = () => {
      history.replace({ pathname: `${PATH.EVSES}/${serialNumber}${PATH.ERROR}`, search: location.search });
    };

    window.addEventListener('popstate', handleHistory);
    return () => {
      window.removeEventListener('popstate', handleHistory);
    };
  }, []); //eslint-disable-line

  return (
    <div className={styles.error}>
      <img src={iconTip} alt="tip" />
      <div className={styles.title}>{title}</div>
      <div className={styles.desc}>{desc}</div>
      {button && (
        <Link
          onClick={() => amplitude.errorReauthorizeOnClick({ serialNumber, errorCode: error, text: title })}
          to={`${PATH.EVSES}/${serialNumber}`}
        >
          返回重新授權
        </Link>
      )}
    </div>
  );
};

export default Error;
