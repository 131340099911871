import { useCallback } from 'react';
import styles from './index.module.sass';
import icon from 'images/icon-pnc.svg';
import { CUSTOMER_SERVICE_MAIL, LINE_LINK } from 'config';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

const Wrapper = ({ bgTheme, children }) => {
  const wrapperClass = cx({
    wrapper: true,
    [bgTheme]: true,
  });

  const WiggleBalls = useCallback(() => {
    return (
      <div className={styles.wiggle}>
        <div className={styles.ball1} />
        <div className={styles.ball2} />
        <div className={styles.ball3} />
      </div>
    );
  }, []);

  return (
    <div className={wrapperClass}>
      <WiggleBalls />
      <div>{children}</div>
      <footer>
        <ul className={styles.contact}>
          <li>
            <a href={`mailto:${CUSTOMER_SERVICE_MAIL}?subject=［客服］皮卡充`}>客服信箱</a>
          </li>
          <li>
            <a href={`https://line.me/R/ti/p/${LINE_LINK}`}>客服 LINE</a>
          </li>
          <li>
            <a href={`mailto:${CUSTOMER_SERVICE_MAIL}?subject=［合作］皮卡充`}>合作洽詢</a>
          </li>
        </ul>
        <div>
          <img src={icon} alt="pick-n-charge-icon" />
          PicknCharge © 2021
        </div>
      </footer>
    </div>
  );
};

export default Wrapper;
