export const TIMEOUT = 'timeout';
export const AUTH_FAIL = 'auth-fail';
export const INIT_FAIL = 'init-fail';
export const CONFIRM_FAIL = 'confirm-fail';
export const EVSE_NOT_FOUND = 'evse-not-found';
export const EVSE_IN_USE = 'evse-in-use';
export const UNKNOWN = 'unknown';

export const errorHandler = errorCode => {
  switch (errorCode) {
    case TIMEOUT:
      return {
        title: '充電逾時',
        desc: '已超過時間尚未啟動充電，請重新啟動。',
      };
    case EVSE_NOT_FOUND:
      return {
        title: '充電樁代碼錯誤',
        desc: '請重新嘗試，或掃描其他充電樁。',
      };
    case INIT_FAIL:
      return {
        title: '無法啟動充電',
        desc: '請稍後再試。',
        button: true,
      };
    case AUTH_FAIL:
      return {
        title: '授權付款失敗',
        desc: '預授權付款失敗，請返回上一步，重新再次完成授權付款。',
        button: true,
      };
    case CONFIRM_FAIL:
      return {
        title: '充電樁連線錯誤',
        desc: '請重新授權。',
      };
    case EVSE_IN_USE:
      return {
        title: '充電樁正在使用中',
        desc: '請重新授權。',
      };
    case UNKNOWN:
    default:
      return {
        title: '未預期錯誤',
        desc: '發生未知錯誤，請重新嘗試。',
      };
  }
};
