import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { MAINTENANCE_CONFIG_URL, PATH } from './config';
import useInit from 'utils/use-init';
import amplitude from 'service/amplitude';
import 'styles/normalize.sass';
import 'styles/base.sass';
import { AppProvider } from 'use-app';
import Entry from 'pages/entry';
import ChargingRecords from 'pages/charging-records';
import Error from 'pages/error';
import Terms from 'pages/terms';
import Privacy from 'pages/privacy';
import ChargingRecordsConfirm from 'pages/charging-records-confirm';
import Loading from 'components/loading';
import axios from 'axios';
import Maintenance from 'components/maintenance';

const App = () => {
  const [appLoading, setAppLoading] = useState(true);
  const [maintenanceConfig, setMaintenanceConfig] = useState(null);

  useInit();

  useEffect(() => {
    amplitude.init();

    axios
      .get(MAINTENANCE_CONFIG_URL, {
        timeout: 2000,
      })
      .then(res => {
        let data = res.data.default;

        if (data['all'].enabled) {
          setMaintenanceConfig(data['all']);
        } else if (data.hasOwnProperty('web')) {
          setMaintenanceConfig(data['web']);
        } else {
          setMaintenanceConfig({ enabled: false });
        }
      })
      .catch(err => {
        console.log(err);
        setMaintenanceConfig({ enabled: false });
      })
      .finally(() => {
        setAppLoading(false);
      });
  }, []);

  if (appLoading || !maintenanceConfig) {
    return <Loading />;
  } else if (maintenanceConfig.enabled) {
    return <Maintenance title={maintenanceConfig.title} content={maintenanceConfig.content} />;
  } else {
    return (
      <AppProvider>
        <Router>
          <Switch>
            <Route exact path={`${PATH.EVSES}/:serialNumber`}>
              <Entry />
            </Route>
            <Route exact path={`${PATH.EVSES}/:serialNumber${PATH.CHARGING_RECORDS}/:id/confirm`}>
              <ChargingRecordsConfirm />
            </Route>
            <Route exact path={`${PATH.EVSES}/:serialNumber${PATH.CHARGING_RECORDS}/:id`}>
              <ChargingRecords />
            </Route>
            <Route exact path={`${PATH.EVSES}/:serialNumber${PATH.ERROR}`}>
              <Error />
            </Route>
          </Switch>
          <Route path={PATH.TERMS}>
            <Terms />
          </Route>
          <Route path={PATH.PRIVACY}>
            <Privacy />
          </Route>
        </Router>
      </AppProvider>
    );
  }
};

export default App;
