import { useEffect } from 'react';
import { PATH } from 'config';
import { Link } from 'react-router-dom';
import styles from './index.module.sass';

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.terms}>
      <h1>服務條款</h1>
      <h5>更新時間：2021.08 Ver. 1.0</h5>
      <br />
      <p>大聲公行動股份有限公司（以下簡稱「本公司」）係依據本條款，提供「皮卡充」（以下簡稱「本服務」）之服務。</p>
      <p>
        當您使用本服務時，即表示您已閱讀、瞭解並同意接受本約定，如果您經過考慮後不同意本條款的內容，請您立即停止使用本服務。若您未滿十八歲，或為法令規定未具備完全行為能力之人，請您由家長（或監護人）陪同閱讀本條款，在您的家長（或監護人）詳讀、瞭解並同意後，您才能使用或繼續使用本服務。當您使用或繼續使用本服務時，即推定您的家長（或監護人）已詳讀、瞭解並同意接受本條款的所有內容及其後修改變更。
      </p>
      <h3>一、服務條款</h3>
      <ol>
        <li>
          本服務提供使用者，得利用信用卡及其他支付方法（包括第三方支付帳號，如Line Pay、Apple Pay等、簽帳金融卡「Debit
          Card」）（下稱「支付方式」），作為使用本公司合作之充電樁充電時，給付因充電所生之消費對價的方法。本服務並不包含充電電力之供應，本公司僅為您串接支付資訊，並提供充電時之必要資訊，不承擔充電的履行及其相關責任。
        </li>
        <li>本服務合作之充電樁，為AC慢充，其餘充電規格，以服務頁面之說明為準。</li>
        <li>使用本服務時，應先掃描本服務合作之充電樁外殼上之QR Code，進入服務頁面，並依服務頁面之指示操作。</li>
        <li>
          本服務會提供您在包括累計充電時間、開始及結束時間、充電瓦數、預計費用、充電狀態（顯示為：已開始、預估已完成、已完成、強制結束充電）等資訊，但本公司並不擔保該資訊之正確無誤，仍應依使用本服務時之實際狀態為準，本公司並保留調整之權利。
        </li>
        <li>
          您瞭解本服務之功能，並在使用本服務時，同意本服務傳送服務性或商業性資料供您參考，包括但不限於下列事項（您能隨時能利用手機或本服務內建之功能，停止接收這些資料或電子郵件）：
          <ol>
            <li>
              本服務將根據您所在位置，推播本服務合作對象的消費資訊，除所在位置、各點營業時間、收費與付款方式外，另不定期以行動推播或寄送電子郵件之方式，分享合作對象的優惠消息。
            </li>
            <li>
              如您於使用本服務時提供您的信用卡資訊，本服務將以前述方式，提供各家信用卡優惠訊息，優惠訊息包含但不限於各公開網站可搜集到資料，信用卡優惠訊息是提供信用卡資訊的會員所獨享的。
            </li>
          </ol>
        </li>
        <li>
          如您不同意本條款所載之任何內容，即不應使用本服務。本公司得隨時依業務推展及消費者需求，自主決定增加、修改或向您終止本條款。但於增加或修改本條款之情形本公司應將修訂後之條款刊登於網站或以其他方式通知您，如您不同意本條款之變更內容，應立即停止使用本服務。
        </li>
      </ol>
      <h3>二、個人資料安全</h3>
      <ol>
        <li>
          為了提供確實有效的服務，您必須保證在使用本服務所留存的所有資料均為完整、正確、與當時情況相符的資料，如有填寫錯誤，請立即於服務頁面更正。若您留存之資料非屬您本人所有，例如第三人所有之車輛資料或第三人之電子郵件信箱等，您在此擔保您已獲有相應且充分之授權。若您提供任何錯誤或不實、或未經授權的資料，除您必須自行負擔因此造成的全部損失外（包含但不限於本公司受第三人求償之損失），本公司並有權暫停或終止本服務。
        </li>

        <li>
          您所填寫之資料如有重複、冒用他人名義、違反公序良俗、或有侵害他人姓名權、公司名稱、商標或其他智慧財產權之虞、或有造成他人誤解之虞、或涉有其他違反法令之嫌、或侵害本公司權利的情況，本公司得拒絕提供本服務、或要求您更正為正確、合法、妥適之資料，或暫停、終止您的使用資格（如有）及相關權利，並且，您應自負一切民事與刑事上之責任。
        </li>

        <li>
          對於您所留存的資料，其目的在於提高使用者資料真實性，提供更佳的服務與優惠。本服務遵守中華民國個人資料保護法令相關規定之規範，除了可能涉及違法、侵權、或違反使用條款、或提供顧客服務而提供給服務之配合廠商、或經本人同意以外，這些資料將只提供本公司於隱私權政策的範圍內，進行利用，絕不會任意透露、出售、交換、或出租給其他第三人、單位及團體；如不同意前揭個人資料之利用，將無法享有本公司或本公司與第三人合作提供之相關權益及優惠。在您同意上述資料蒐集後，仍可依據隱私權政策的有關規定，向本公司申請變更、刪除或停止利用資料中包含您個人資料的部份。具體規範，請參考本公司的隱私權政策。隱私權政策之內容，為本條款之一部分，皆對您有拘束力。
        </li>
        <li>
          為確保您之權益，請您務必詳細閱讀本服務之 <Link to={PATH.PRIVACY}>隱私權政策</Link>
          以利知悉本公司如何蒐集、處理及利用您的個人資料。當您使用本服務，即視為您同意隱私權政策之規定；若您不同意隱私權政策部分或全部之內容，請勿使用本服務。
        </li>
      </ol>
      <h3>三、智慧財產權</h3>
      <ol>
        <li>
          本服務內提供的任何資訊內容，包括但不限於文字、美術作品、圖標、圖像及商標，乃至本服務軟體的功能介面呈現，均係大聲公行動股份有限公司擁有的智慧財產權，受到中華民國法律所保護。在未得到大聲公行動股份有限公司發出的事先書面許可前，您不得以任何方式就任何目的將本服務的內容或其任何部份進行重製、拷貝、出售、交易、轉售其內容或作任何商業目的之使用，並同意不會移除或變更本服務的內容中所包含的任何防盜拷措施，或其它權利保護措施（包括且不限於專利權、商標權）或標記；惟您可將本服務之資訊內容，在基於非商業性、家居及個人使用的目的範圍內，自行利用，但仍應確保本公司之智慧財產權不受第三人之侵害。
        </li>
        <li>
          本公司僅授權您使用本服務，而並非將本服務所提供的內容、文字檔及任何編碼的智慧財產權移轉給您。為免存疑，一切本公司沒有在本條款中明確授予您的權利，均由本公司保留。
        </li>
      </ol>
      <h3>四、付款流程與確認機制</h3>
      <ol>
        <li>
          您應先確認充電樁之狀態顯示為「可使用」再使用本服務。本服務是依您使用本服務之時間，以每分鐘為單位計價，若不足1分鐘，以1分鐘計（範例如下），自您插入充電槍並完成授權之金流圈存後開始充電，充電至您將充電槍拔出後方停止計價。請注意，若充電完成但您並未拔槍停止充電，則本服務將繼續計價至您拔出充電槍或充電超過3,000分鐘為止，視何項條件先達成。本服務之計費模式，將視營運情形而定，以服務頁面之公告為準，本公司並保留調整之權利。
          <br />
          範例：
          <br />
          若車主充電使用時間為1分01秒，會以2分鐘計算本服務使用時間，乘以每單位服務費用（假設為新台幣1元），則本服務將向您收取新台幣2元。
        </li>
        <li>
          本服務將根據您採取之支付方式或輸入之信用卡等支付資訊，依您使用本服務之時間結算使用費，向您使用之支付業者或信用卡收單機構進行請款。
        </li>
      </ol>
      <h3>五、消費記錄與優惠認定</h3>
      <ol>
        <li>
          如您使用本服務並填載電子郵件地址，本服務所產生的消費記錄，將生成訂單編號寄送至您留存之電子郵件地址，您可依消費記錄了解本身付款狀況。
        </li>
        <li>
          若本服務有提供消費優惠，您可依該消費優惠之使用規則，於期限內於本服務使用；使用期間之認定，依照本公司伺服器收到訂單完成而送出請款請求之時間為準。若因任何因素（不可抗力除外）造成場域傳送延誤，使得優惠無法使用，本公司概不負責。
        </li>
      </ol>
      <h3>六、 錯誤支付之處理</h3>
      <ol>
        <li>
          因不可歸責您之事由而發生支付錯誤之情況時（例如：信用卡或帳號等資訊遭盜用，或其他軟硬體之技術問題造成訂單不應產生卻完成支付流程等等）本公司應協助您進行更正，並提供必要之協助，且得視具體情況先行停止交易或凍結您的使用。
        </li>
        <li>因可歸責於本公司之事由而發生支付錯誤之情況時，本公司將於知悉時進行即時更正，並以電子郵件之方式通知您。</li>
        <li>
          因可歸責於您之事由而發生支付錯誤之情況時，您有即時通知本公司之義務，本公司將於知悉後協助您進行必要之處理。
        </li>
      </ol>

      <h3>七、禁止行為</h3>
      <p>您聲明並保證，不會從事以下行為或符合以下描述（下稱「禁止行為」）：</p>
      <ol>
        <li>違反本條款或任何適用於本服務之政策、規範或其他與您之間之約定；</li>
        <li>
          使用本服務時違反任何法律、法規命令（包括任何反洗錢或反資助恐怖主義之法律或法令）、行政指導，或法院判決與主管機關之處分；
        </li>
        <li>以危害公序良俗之方式使用本服務；</li>
        <li>以侵害本公司或第三人之權利之方式使用本服務；</li>
        <li>於本服務網頁未列舉之國家登入本服務；使用偽造、不正確或誤導之資訊使用本服務；允許非本人之人使用本服務；</li>
        <li>未經本公司事前同意，利用本服務作為交易其他商品或服務之對價；</li>
        <li>利用本服務從事詐欺或其他非法交易；</li>
        <li>使用無效的或他人的金融機構帳號（包括信用卡帳號）使用本服務；</li>
        <li>透過本服務購買法律或國際信用卡組織禁止或限制之物品或服務，或購買仿冒品；</li>
        <li>干擾本服務之電腦伺服器或網路系統；</li>
        <li>使用不適當之技術方法操控本服務；</li>
        <li>
          複製、改造或公開傳輸本公司為提供本服務所使用之軟體、程式、資料庫及應用程式介面（API）（但不包括因使用本服務所需之公開傳輸），及使用逆向工程、拆解或其他方法以分析上開軟體、程式、資料庫及應用程式介面（API）。
        </li>
        <li>經本公司合理要求並說明目的，拒絕配合調查、確認身份或提供文件以佐證提供予本公司之資訊；</li>
        <li>
          以會導致或可能導致本公司，以及包含其他使用者、本公司之合作廠商在內之第三人遭申訴、告訴、訴訟罰鍰、處分或負擔其他法律責任之方式，使用本服務；
        </li>
        <li>曾經信用評等機構評定您使用本服務為高風險；</li>
        <li>自信用卡預借現金（或協助他人為之）；</li>
        <li>其他妨礙本服務之正常營運；或從事對本公司造成損害或有損害之虞的任何不當行為。</li>
      </ol>
      <p>
        如您違反本條款之內容者，本公司得不經催告，隨時暫停對您提供本服務之全部或任何部分，並得終止本條款。您如因違反本條款之行為，造成本公司任何損失或損害，並應賠償本公司，包括但不限於訴訟費用、律師費。
      </p>
      <h3>八、充電樁設備之使用</h3>
      <ol>
        <li>
          使用本服務時，您應遵循現場充電樁設備之使用規範，嚴禁塗鴉、拆解、破壞、敲擊、摔落、使用任何溶劑或化學藥劑，或以其他不正常之使用方式使用或改變充電樁設備，也禁止將充電樁設備用於本服務範圍以外之用途。若因您的行為造成充電樁設備之損壞，您需全額賠償本公司；若本公司需以訴訟或其他方式向您請求該費用，您並應賠償本公司相關之訴訟費用（包括但不限於採證費用、律師費用）。
        </li>
        <li>若充電樁設備發生故障或有其他問題，請立即與本公司聯繫，嚴禁自行處理或丟棄充電樁設備。</li>
      </ol>
      <h3>九、被盜用資料之處理</h3>
      <ol>
        <li>
          您一旦發現或懷疑使用本服務之個人資料被盜用，或被任何第三人以未經授權之方式使用，您應依照本服務登載之聯繫方式，通知本公司並停用本服務以及爭議付款，以使本公司得就此採取適當且及時之措施。惟本公司所採取之該等措施，不應被視為本公司對使用者明示或默示之賠償，亦不應被視為本公司應對使用者負擔任何責任。
        </li>
        <li>
          於知悉本服務之個人資料被盜用，或被任何第三人以未經授權之方式使用時，本公司應立即通知使用者（但如係收受使用者通知而知悉者，即毋須再通知使用者），且暫停本服務任何已經指示之付款，並停止接受該盜用之帳務資料或會員帳號後續之任何支付指示。
        </li>
        <li>
          於本公司受理完成使用者的帳務資料停用手續後，因該帳務資料所發生之損失，由本公司負擔；在完成該帳務資料停用手續前，如因下列原因致該帳務資料發生損失，該損失應由使用者自行負擔：
          <ol>
            <li>使用者未妥善保管帳務資料</li>
            <li>使用者自行將帳務資料提供與他人</li>
            <li>使用者未依照本公司安全機制使用本服務</li>
            <li>其他因使用者故意或重大過失所致之事由</li>
          </ol>
        </li>
        <li>調查使用者帳務資料被盜用所生之費用，應由本公司負擔。</li>
      </ol>

      <h3>十、資訊安全措施</h3>
      <ol>
        <li>
          本公司將定期進行系統更新，並維護本服務所蒐集之使用者資訊，防止第三人非法入侵、取得、竄改、毀損交易記錄或其個人資料。因上述情形而造成對您之損害，如可歸責於本公司時，您同意以另行協商之方式，決定雙方負擔之比例。
        </li>
        <li>前項情況經查證屬實，本公司得視具體情況，停止本服務之提供。</li>
      </ol>

      <h3>十一、免責聲明</h3>
      <ol>
        <li>
          在準備、蒐集本服務所提供資料的過程中，本公司已經盡最大可能採取謹慎的態度，但還是提醒您，除可歸責於本公司之情形外，本公司不會因任何資料的錯誤或遺漏，或因您信賴本服務或其介面上之說明而對提供資料所造成的損害，承擔契約責任。
        </li>
        <li>
          本服務可能因網路或是硬體設備、天災等其他因素，造成服務中斷、故障、延遲、資料傳輸或儲存錯誤等情形，若發生前述異常，本服務會強制結束，您同意並理解本公司對於此等情況，不承擔任何損害賠償責任。
        </li>
        <li>
          除本約定書另有規定外，在任何情況下，本公司不對下列損失負擔損害賠償責任：包括因使用或無法使用本服務而引起的利益損失，或其它直接、間接、意外、特別或衍生性的損失（包括但不限於純粹經濟上的損害、生意中斷、生意資訊的遺失、和任何其它金錢上的損失）。於任何情況下，本公司應承擔之契約責任，以所受損害為限，並不包含所失利益。
        </li>
      </ol>
      <h3>十二、與本公司之爭議</h3>
      <ol>
        <li>
          如使用者依破產法令開始任何程序，或經他人依破產法令提起任何程序，本公司應有權請求執行本條款所生之所有合理的成本及費用（包括合理的律師費及費用）。
        </li>
        <li>
          如使用者與一個或多個使用者或與本公司之合作廠商有爭議，使用者應使本公司免於該爭議所生或與該爭議相關之任何種類及性質之請求，以及因此可能衍生的任何損害。
        </li>
      </ol>
      <h3>十三、準據法與管轄法院</h3>
      <p>本約定書之解釋與適用，以及與本約定書有關的爭議，均以中華民國法律為準據法，並以台北地方法院為一審管轄法院。</p>
      <h3>十四、企業經營者資訊</h3>
      <p>
        本服務之擁有者為大聲公行動股份有限公司聯絡方式：
        <a href="mailto:charge@pickncharge.xyz">charge@pickncharge.xyz</a>
      </p>
    </div>
  );
};

export default Terms;
